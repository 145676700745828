import './index.css';

import { Provider } from 'react-redux';

import { io }                         from 'socket.io-client';
import {ServersSteam}                 from './import/socket/SocketIo.js';
import {ConnectIo}                    from './import/socket/ConnectIo.js';
// import {map_cfg_arr}                  from './import/cfg/cfg_maps.js';
import {cfg}                          from './import/cfg/cfg_wsi.js';
// import {render_players}               from './import/canvas/framerender.js';
import {load_ui}                      from './import/cache/load_ui.js';
import React, {useState}              from 'react';
import ReactDOM                       from 'react-dom/client';

import ServersBody                    from './import/structure/MainPage/LeftBox/ServersBody.js';
import MonitoringBody                 from './import/structure/MainPage/MainBox/MonitoringBody.js';

import FA                             from './import/font-awesome-pro/index.js';
import { storeServer }                from './import/store/StoreServer.js';
import { storeServersList }                from './import/store/StoreServersList.js';

// const FAputBox                     = ReactDOM.createRoot(document.getElementById('test_output_box_tick'));
const WSI                             = ReactDOM.createRoot(document.getElementById('monitoring-gotv'));

load_ui()
window.c = function (c){console.log(c)}

window.time          = new Date().getTime() / 1000

window.cs2_rooms 	   = {};
window.arr_plrs      =	[];
window.UsersTeam     = new Array()
window.serversList	= new Array();

window.arr_fire		= new Array()
window.arr_hurt		= new Array()
window.arr_blind		= new Array()
window.arr_deaths		= new Array()

window.arr_fe		= new Array()	//faje events

window.short_visible		= '0';	//БН (базовая настройка)
window.radar_trans_zx	= 0			//БН
window.radar_trans_zy	= 0			//БН
window.radar_scale		= 1			//БН
window.curr_map       	= 'awp_lego_2'
window.fps_tact       	= 0
// window.arr_fire=new Array()

window.wpc4=new Array()





window.onlyOneFunction = false

window.socket = io('91.211.117.32:1333');
// window.socket = io('glite.com.ua/servers/');
window.connector = new ConnectIo();


const _ServersSteam = new ServersSteam();
_ServersSteam.StartSteam()
// render_players()
/*автоподключение после перезагрузки страницы */
if(localStorage['sid']!==undefined && localStorage['sid_time']>window.time-3600){
  window.connector.Connect(localStorage['sid'])
} else {
  window.connector.Connect('912111173227015')
}


// FAputBox.render(<FA name="abacus" style="duotone"/>)

WSI.render(
  <>
    <ServersBody />
    <Provider store={storeServer}>
        <MonitoringBody/>
    </Provider>
  </>
  )