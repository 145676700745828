import { Provider, useSelector } from 'react-redux';

import RoundTime from './RoundTime.js'
import RenderMapCanvas from './Canvas/RenderMapCanvas.js'
import RenderPlayersCanvas from './Canvas/RenderPlayersCanvas.js'
import PlayersRender from './Players/PlayersRender.js'
import { storePlayersList } from './../../../store/StorePlayersList.js';
import { NetGraph } from './Hud/NetGraph/NetGraph.js';
import FA from './../../../font-awesome-pro';
// import FE from './FakeEvents.js';
import MenuSlide from './MenuSlide/index.js';
import PlayersDeathList from './Players/PlayersDeathList.js';
// import {OpenSlideMenu} from './SlideMenu/OpenSlideMenu.js';

const MonitoringBody = () => {
	const server = useSelector(state => state.data);
	// "server_full": true,
	// "ip": "91.211.117.32",
	// "hostport": 27020,
	// "serverid": "912111173227020",
	// "hostname": "[GLITE] Бунт на пределе [==|. .|==]",
	// "Players": 11,
	// "MaxPlayers": 32,
	// "map": "de_dust2",
	// "current_time": 73473.05,
	// "current_time_u": 1715520216,
	// "round_start": 6.09375,
	// "round_time": 999,
	// "scrore_2": 0,
	// "scrore_3": 0,
	// "HasMatchStarted": false,
	// "BombDefused": false,
	// "BombDropped": false,
	// "bomb_planted": false,
	// "BombPlantedTime": 76155.05,
	// "FreezePeriod": false,
	// "FreezeTime": 2,
	// "GamePaused": false,
	// "total_rounds": 0,
	// "game_phase": 2,
	// "status": "online",
	// "lastupdate": 1715520216,
	// "outdate": false
	// console.log(server.map)
	return (
		<div className='servers-new-rblk' id='servers-new-rblk'>
			<div className='parse_answer' style={{ zIndex: 13, position: 'absolute', left: '12px', top: '585px', fontSize: '26px', color: 'black', background: 'white', padding: '8px', borderRadius: '7px', cursor: 'pointer' }}>оправить</div>
			<div className='test_output_box_fps' id="test_output_box_fps" style={{ zIndex: 13, position: 'absolute', left: '12px', top: '0', fontSize: '13px', color: 'white', background: 'green', padding: '8px 0', borderRadius: '7px', cursor: 'pointer', textAlign: 'center', width: '32px' }}>{window.fps}</div>
			<div className='rblk-plrs-head'>
				<div className='cong-show-left'>
					<div className='box_play_pause'>
						<i className="fa-solid fa-pause render_stop"></i>
						<i className="fa-solid fa-play render_start"></i>
					</div>
				</div>

				<div className='info-show'>
					<i className='game_time'></i>
				</div>
				<div className='plrs-head-t'>Terrorists
					<div className='all_hp_players_back'><div className='t_all_hp all_hp_players_in'></div></div>
				</div>
				<div className='plrs-head-scorex'>
					<div className='plrs-head-score score_t'>{server?.scrore_2}</div>
					<i className='info'>:</i>
					<div className='plrs-head-score score_ct'>{server?.scrore_3}</div>
				</div>
				<div className='plrs-head-ct'>C-Terrorists
					<div className='all_hp_players_back'><div className='ct_all_hp all_hp_players_in'></div></div>
				</div>
				<div className='round_status_time'>
					<RoundTime data={server} />
				</div>
				<div className='config_bar'>
					<MenuSlide/>
					
					<FA name="gear" style="light"/>
				</div>
				{/* <div className='cong-show'>
					<i className="fa-regular fa-eye show_spec"></i>
					<i className="far fa-info-square" data-id='pg_moreinfo'></i>
					<i className="fa-regular fa-gear open_config_gtv" ></i>
				</div> */}
			</div>
			<div className='overflow_players_box' id='overflow_players_box'>
				{/* <canvas width = "826" height = "826" id='map_players'></canvas> */}
				<RenderMapCanvas map={server?.map} />
				<RenderPlayersCanvas server={server} />
				{/* <canvas width = "826" height = "826" id='radar_monitor_playrs_ex'></canvas> */}

				<Provider store={storePlayersList}>
					<PlayersRender />
				</Provider>

				<PlayersDeathList/>
			</div>
			<div className='plrs-1 hiddenx_spectrs'></div>
			<div className='chat_server_box'>
				<div className='chat_server_body'></div>
			</div>
			<div className='monitoring_hud'>
				{/* <NetGraph/> */}
			</div>
		</div>
	)
}
export default MonitoringBody