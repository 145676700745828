import {fmtMSS} from './../../../fmtMSS.js'
const RoundTime = ({data})=>{
    // console.log(data)
    if(data==null)return null;
    const rTime = fmtMSS(data.round_start+data.round_time-data.current_time)
    // console.log (data.has_match_started)
    if(data.bomb_planted){
        return(
            <>
                <div className='bomb_time_line' style={{width:100/40*(data.bomb_planted_time-data.current_time+40)+'%'}}></div>
                <div className='time_line'>{window.svg_icons['bomb_c4_red']}</div>
            </>
        )
    } else if(data.bomb_defused==false && data.bomb_planted==false){
        if(data.freeze_period){
            return (
                <div className='time_line freeze'>o{fmtMSS(data.round_start-data.current_time)}</div>
            )
        } else if(data.has_match_started==false) {
            return(
                <div className='time_line'>Разминка {(data.round_start+data.round_time-data.current_time>0)&&rTime}</div>
            )
        } else if(data.round_start+data.round_time-data.current_time>0){
            if(data.round_start+data.round_time-data.current_time<10){
                return(
                    <div className='time_line end_round'>x{rTime}</div>
                )
            } else {
                return(
                    <div className='time_line round'>y{rTime}</div>
                )
            }
        }else{
            return(
                <div className='time_line' title={Math.round(data.round_start+data.round_time-data.current_time)}>RoundEnd</div>
            )
        }
    }
    // console.log(Math.round(new Date().getTime() / 1000))
        
            // const round_end = round_t['round_start']+round_t['round_time']-round_t['current_time']
            // if(round_end>round_t['round_time']){																//1
            //     $('.time_line').html(fmtMSS(GamePaused-round_t['round_time']))
            //     if(round_timer_type!=1){$('.time_line').css({'color':'red'});round_timer_type=1}
            // }else if(round_end<10&&round_end>1){									//2
            //     if(prev_round_end!=fmtMSS(round_end))$('.time_line').html(fmtMSS(round_end))
            //     if(round_timer_type!=2){$('.time_line').css({'color':'red'});round_timer_type=2}
            // }else if(round_end>1){															//3
            //     if(prev_round_end!=fmtMSS(round_end-round_t['round_time']))$('.time_line').html(fmtMSS(round_end))
            //     if(round_timer_type!=3){$('.time_line').css({'color':'white'});round_timer_type=3}
            // }else{																					//4
            //     // if(round_timer_type!=4){round_timer_type=4;$('.time_line').html('0:00')}
            // }
            // prev_round_end=fmtMSS(round_end)
    // }else{
    
    // }
}
export default RoundTime;