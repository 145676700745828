import React, { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/konva';
import { Circle } from 'react-konva';
import EventBus from './../../Events/EventBus.js';

const AnimatedCircle = animated(Circle);

const UserDamage = ({ userid, x, y }) => {
    const [toggle, setToggle] = useState(false);

    const { fillRadialGradientEndRadius } = useSpring({
        fillRadialGradientEndRadius: toggle ? 10 : 20,
        config: { duration: 500 },
        reset: true,
        onRest: () => setToggle(false),
    });

    useEffect(() => {
        const handlePlayerDamage = ({ item }) => {
            if (item.userid === userid) {
                setToggle(true);
            }
        };

        EventBus.on('EventPlayerDamage', handlePlayerDamage);

        return () => {
            EventBus.off('EventPlayerDamage', handlePlayerDamage);
        };
    }, [userid]);

    return (
        <AnimatedCircle
            key={`Damage${userid}`}
            name={`Damage${userid}`}
            x={x}
            y={y}
            fillRadialGradientStartPoint={{ x: 0, y: 0 }}
            fillRadialGradientEndPoint={{ x: 0, y: 0 }}
            fillRadialGradientColorStops={[0, '#00000000', 1, toggle ? 'red' : '#00000000']}
            radius={9}
            fillRadialGradientEndRadius={fillRadialGradientEndRadius}
        />
    );
};

export default UserDamage;
