export const cfg_maps = new Array();
cfg_maps['de_mirage'] = {
	"pos_x"	: "-3230",	// X coordinate,
	"pos_y"	: "1713",	// Y coordinate,
	"scale"	: "5.00", 	// and used scale used when taking the screenshot
	"rotate": "0",	// map was rotated by 90 degress in image editor
	"zoom"	: "0",	// optimal zoom factor if map is shown in full size
};
cfg_maps['awp_lego_2'] ={
	"pos_x"	: "-1231",
	"pos_y" : "1278",
	"scale"	: "2.50", 
	"rotate": "1",
	"zoom" 	: "1.3",
};
cfg_maps['awp_lego_2_xmass_night'] ={
	"pos_x"	: "-1231",
	"pos_y" : "1278",
	"scale"	: "2.50", 
	"rotate": "1",
	"zoom" 	: "1.3",
};
cfg_maps['de_dust2'] ={
	"pos_x"	: "-2476", 
	"pos_y" : "3239",
	"scale"	: "4.4",
	"rotate": "1",
	"zoom"	: "1.1",
};