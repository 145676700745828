import Konva from 'konva';
import { Circle} from 'react-konva';
const PointMax	= 100

const UserPoint = (data) => {
	// console.log(data)
	const x = data.x
	const y = data.y
	const stage = data.stage
	return (
		<>
			{(stage<100)&&(
				<Circle
					key={`CPlPoint1`}
					x={x}
					y={y}
					stroke='white'
					opacity={1-stage/100}
					radius={PointMax/100*stage}
				/>
			)}
			{(stage>35 && stage < 135)&&(
				<Circle
					key={`CPlPoint2`}
					x={x}
					y={y}
					stroke='white'
					opacity={1-(stage-35)/100}
					radius={PointMax/100*(stage-35)}
				/>
			)}
			{/* {(stage>75)&&(
				<Circle
					key={`CPlPoint3`}
					x={x}
					y={y}
					stroke='white'
					opacity={1-(stage-75)/100}
					radius={PointMax/100*(stage-75)}
				/>
			)} */}
		</>
	)

}
export default UserPoint