// import PlayersSort                      from './../structure/MainPage/MainBox/Players/PlayersSort.js';
import {cfg}                	from './../../../../cfg/cfg_wsi.js';
import React, { Component } 	from 'react';
import { Stage, Layer, Image } 	from 'react-konva';
  class URLImage extends React.Component {
	state = {
	  image: null,
	};
	componentDidMount() {
	  this.loadImage();
	}
	componentDidUpdate(oldProps) {
	  if (oldProps.map !== this.props.map) {
		this.loadImage();
	  }
	}
	componentWillUnmount() {
	  this.image.removeEventListener('load', this.handleLoad);
	}
	loadImage() {
	  this.image = new window.Image();
	  this.image.src = `/img/maps/${this.props.map}/${this.props.map}_radar_1024.webp`;
	  this.image.addEventListener('load', this.handleLoad);
	}
	handleLoad = () => {
	  this.setState({
		image: this.image,
	  });
	};
	render() {
	  return (
		<Image
		  x={0}
		  y={0}
		  width={cfg.width}
		  height={cfg.width}
		  image={this.state.image}
		  ref={(node) => {
			this.imageNode = node;
		  }}
		/>
	  );
	}
  }
  
  const RenderMap = ({map})=> {
	if(map==undefined)return ''
	window.curr_map=map;
	  return (
		<Stage width={cfg.width} height={cfg.width} className='CanvasMap' id='radar_monitor_img_back'>
			<Layer>
				<URLImage map={map}/>
				{/* <LoadImage map="de_dust2"/> */}
			</Layer>
		</Stage>
	  );
  }
  export default RenderMap 
