import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { storeServersList }                            from './../../../store/StoreServersList.js';
import ServersList from "./ServersList.js"
const ServersBody = () =>{
	// console.log(storeServersList)
    return (
		// {storeServersList},
        <div className='servers-lblk-men'>
			<div className='servers-lblk' id="servers-lblk">
				<Provider store={storeServersList}>
					<ServersList />
				</Provider>
			</div>
		</div>
    )
}
export default ServersBody