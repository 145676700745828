import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import EventBus from './../Events/EventBus.js';
import PlayersTitle from './PlayersTitle.js'
import RenderWeapons from './RenderWeapons.js'
///////////////////////////////////////////////////////////////////

let selectUser, selectUserTimeout, time;
const handleUserClick = (userid) => {
	EventBus.emit('handleUserClick', { userid });
	(selectUser === userid) ? selectUser = undefined : selectUser = userid
}


const PlayersRender = () => {
	const [isShort, setIsShort] = useState(false);
	const h_SetShort = () => {
		setIsShort(!isShort)
	};
	useEffect(() => {
		const handleEvent = (data) => {
			selectUser = data.e.target.attrs.userid
			clearTimeout(selectUserTimeout)
			selectUserTimeout = setTimeout(() => {
				selectUser = undefined
			}, 1500);
		};

		EventBus.on('ClickCircle', handleEvent);
		EventBus.on('e_SetShort', h_SetShort);
		return () => {
			EventBus.off('ClickCircle', handleEvent);
			EventBus.off('e_SetShort', h_SetShort);
		};
	}, []);

	const data = useSelector(state => state.data);
	if (data === null || data === undefined) return '';
	return (
		time = new Date().getTime(),
		data.map((side, sideIndex) => (
			(sideIndex > 1) ? (
				<div key={sideIndex} className={`plrs-${sideIndex}-box`}>
					<PlayersTitle side={sideIndex} isShort={isShort} />
					<div className={`plrs_bx plrs-${sideIndex}`} id={`plrs-${sideIndex}`}>
						{side.map((user, userIndex) => (
							<div className={`plrs_inform  mini_profile${(user.alive === false) ? ' death' : ''} pl-${sideIndex} ${(selectUser === user.userid) ? 'select' : ''} ${(isShort) && "short"}`}
								key={user.userid}
								data-id={user.userid}
								data-top={user.top}
								data-steam={user.steamid}
								data-r_kills={user.r_kills}
								data-alive={user.alive}
								style={{ top: (user.top > 0) && user.top * 60 }}
								onClick={() => handleUserClick(user.userid)}
							>
								{/* {(isShort)&&<div>asd</div>} */}
								<div className='ava_box addblock_button online_led' data-steam=''>
									<img className='ava' id={user.steamid} src={`/img/ui_icons/teams_logo/${user.team}_logo.png`}></img>
									<div className='damage_box'><div className='hp_left'></div></div>
									<div name='' className='img_svg_box wp_c4'></div>
								</div>
								<div className='plrs-in-inn'>
									<div className={`plrs-in-innn-a ${(isShort) && "short"}`}>
										<div className='innn-a-clan'>{(user.ping === 0) ? '[BOT]' : `[${user.clan}]`}</div>
										<div className='innn-a-name addblock_button' data-steam=''>
											<a href='/#' className='name-plrs' target='_blank'>{user?.name}</a>
										</div>
										<div className={`innn-a-kd`}>
											<i className='kd-v ping' title='Пинг'>{(user.update - time > 1000) ? 'offline' : user.ping}</i>
											<i className='kd-v mvps' title='Самый ценный игрок раунда'>{user.mvps}</i>
											<i className='kd-v deaths' title='Смертей'>{user.deaths}</i>
											<i className='kd-v assists' title='Помощь убийства'>{user.assists}</i>
											<i className='kd-v kills' title='Убийств'>{user.kills}</i>
											<i className='kd-v score' title='Счет'>{user.score}</i>
										</div>
										<div className='innn-a-kd2 hiddenx'>
											<i className='kd-v kda' title='Соотношение (Убийств+Помощи)/Смертей'></i>
											<i className='kd-v kd' title='Соотношение убийств/смертей'></i>
											<i className='kd-v r_kills' title='Убийств в раунде'></i>
										</div>
									</div>
									<div className='plrs-in-innn-b'>
										<div className='innn-b-money'>{user.money}</div>
										<div className='innn-b-grenades'></div>
										<RenderWeapons weapons={user.weapons} active_weapon={user.active_weapon} userid={user.userid} />
									</div>
									<div className='plrs-in-innm-c' title='Убийств в раунде'></div>
									<i className='status_serv'>
										{/* <!--<i className="status_donate" title='Донат'>[Vip]</i>--> */}
										<i className="friend_status hiddenx fas fa-user-alt" title='У Вас в друзьях'></i>
										<i className="fas fa-tombstone hiddenx" title='Мертв'></i>
										<div className='mute_url mute_chat' href=''><i className="fa-solid fa-microphone-lines-slash"></i></div>
										<div className='mute_url mute_gag' href=''><i className="fa-solid fa-comment-slash"></i></div>
									</i>
								</div>
								<div className='health_box'>
									<div className='armor_box_bottom' style={{ width: `${(user.alive === true) ? user.armor : 0}%` }}></div>
									<div className='hp_box_bottom' style={{ width: `${(user.alive === true) ? user.health : 0}%` }}></div>
								</div>
							</div>
						))}
				</div>
				</div >
			) : ('')//нарисовать наблюдателей
		))
	)
};

export default PlayersRender;