import React, { useEffect, useState } from 'react';
import EventBus from './../Events/EventBus.js';
import { cfg } from './../../../../cfg/cfg_wsi.js';
import SVG from 'react-inlinesvg';

var DeathHist = []

// {
// 	"time": 1718173155318,
// 	"item": {
// 		"serverid": "912111173227020",
// 		"event_name": "EventPlayerDeath",
// 		"userid": "1",
// 		"pos_userid": {
// 			"x": -383.72412,
// 			"y": 1618.2266,
// 			"z": -126.89313
// 		},
// 		"assister": "0",
// 		"assisted_flash": true,
// 		"attacker": "19",
// 		"attacker_blind": true,
// 		"distance": 22.3975,
// 		"headshot": true,
// 		"noscope": true,
// 		"penetrated": true,
// 		"thrusmoke": true,
// 		"weapon": "glock",
// 		// "WeaponFauxitemid": "17293822569102704644",
// 		// "WeaponItemid": "0"
// 	}
// }
const KillLine = ({ row, num, removeSelf }) => {
	const item = row.item;
	const teamAttacker = window.arr_plrs[item.attacker]?.team;
	const teamAssister = window.arr_plrs[item.assister]?.team;
	const teamUserid = window.arr_plrs[item.userid]?.team;

	useEffect(() => {
		const timer = setTimeout(() => {
			removeSelf(row.time);
		}, 5000); // Удаление 

		return () => clearTimeout(timer); // Очистка таймера при размонтировании
	},[]);
	/*}, [row.time, removeSelf]); */

	return (
		<div className='kill_line' key={item.userid + row.time+item?.attacker} style={{ top: (num * 30) + 'px' }}>
			{item.attacker && (
				<div className='kb_attacker'>
					{item.attacker_blind && <SVG src={`/img/ui_icons/blind.svg`} />}
					<div className={`attacker`} style={{ color: `${cfg['team_' + teamAttacker + '_color_name']}` }}>
						{window.arr_plrs[item.attacker]?.name}
					</div>
				</div>
			)}

			{item.assister && (
				<div className='kb_assister'>
					<div style={{ color: 'white', margin: '0 4px' }}>+</div>
					{item.assisted_flash && <SVG className='img_svg_box' src={`/img/weapons/svg/weapon_flashbang_assist.svg`} />}
					<div className='assister' style={{ color: `${cfg['team_' + teamAssister + '_color_name']}` }}>
						{window.arr_plrs[item.assister]?.name}
					</div>
				</div>
			)}

			{item.weapon && (
				<div className='weapon'>
					<SVG className='img_svg_box' src={`/img/weapons/svg/weapon_${item.weapon}.svg`} />
				</div>
			)}

			<div className='kb_user'>
				{item.penetrated === 1 && <SVG src={`/img/ui_icons/penetrate.svg`} />}
				{item.noscope && <SVG src={`/img/ui_icons/noscope.svg`} />}
				{item.thrusmoke && <SVG src={`/img/ui_icons/smoke_kill.svg`} />}
				{item.headshot && <SVG src={`/img/ui_icons/headshot.svg`} />}
				<div className={`userid`} style={{ color: `${cfg['team_' + teamUserid + '_color_name']}` }}>
					{window.arr_plrs[item.userid]?.name}
				</div>
			</div>
		</div>
	);
};

// let 
const PlayersDeathList = () => {
	const [DeathHist, setDeathHist] = useState(window.arr_deaths);

const updateValue = (val) => {
    setDeathHist(val);
    window.arr_deaths = val;
};

const AddDeath = (data) => {
    const time = new Date().getTime();
    const NewDeathsHist = [...window.arr_deaths, { time: time, item: data.item }];
    updateValue(NewDeathsHist);
};

const removeDeath = (time) => {
    const NewDeathsHist = window.arr_deaths.filter(row => row.time !== time);
    updateValue(NewDeathsHist);
};

	useEffect(() => {
		EventBus.on('EventPlayerDeath', AddDeath);
		return () => {
			EventBus.off('EventPlayerDeath', AddDeath);
		};
	}, []);
	var num = 0;
	return (
		<div className='kills_events' key='kill_e'>
			{DeathHist.map((row) => (
				<KillLine key={row.item.userid + row.time+row.item?.attacker} num={num++} row={row} removeSelf={removeDeath} />
			))}
		</div>
	);
};
export default PlayersDeathList