import React, { useState } from 'react';
import FA from './../../../../font-awesome-pro';


const MenuLine = () => {
	return(
		<>
			<div className='asdasd'
				// onMouseEnter={this.onMouseEnterHandler}
				// onMouseLeave={this.onMouseLeaveHandler}>
			>	LineOfMenu
			</div >
		</>
	)
}

const MenuSlide = () => {
	const [isShown, setIsShown] = useState(false);
	const toggleMenuSlidex = () => setIsShown(!isShown)

	return (
		<div onClick={toggleMenuSlidex}>
			<FA name="sliders" style="light" />
			{(isShown) &&
				<div className="MenuSlideBox">
					<MenuLine text="События" tag="events"/>
				</div>
			}
		</div>
	)
}
export default MenuSlide