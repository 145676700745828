import SVG from 'react-inlinesvg';

const WeaponAmmo = ({ weapon }) => {
	return (
		<div key={weapon.ammo} className='weapon_ammo'>
			<div className='cartridge'>{weapon.reserve}</div>/
			<div className='ammo'>{weapon.ammo}</div>
		</div>
	)
}
const RenderWeaponsBox = ({ box, weapon, active_weapon }) => {
	if (weapon === undefined) return <div key='0'></div>
	if (weapon.length <= 0) return <div key='0'></div>
	return (
		<div key={weapon.name} name='' className={`img_svg_box ${box}${(active_weapon === weapon.name) ? ' active' : ''}`}>
			{(weapon.name !== undefined) &&
				<SVG className='img_svg_box' src={`/img/weapons/svg/${weapon.name}.svg`} />}
			{(active_weapon === weapon.name
				&& !weapon.name.includes('weapon_knife')
				&& !weapon.name.includes('weapon_bayonet'))
				&& <WeaponAmmo weapon={weapon} />}
		</div>
	)
}

const RenderWeapons = (data) => {
	let active_weapon = data['active_weapon']
	// console.log(active_weapon)
	let wp = []
	wp['wp_k'] = []
	wp['wp_t'] = []
	wp['wp_s'] = []
	wp['wp_m'] = []
	let has_wpc4 = false;
	var has_fl = false;
	Object.entries(data['weapons']).map((weapon) => {
		// console.log(weapon[1].name)
		window.arr_plrs[data.userid]['has_weapon_c4'] = false
		if (weapon[1].name.includes('weapon_knife') || weapon[1].name.includes('weapon_bayonet')) {
			wp['wp_k'] = weapon[1]
		} else {
			switch (weapon[1].name) {
				case 'weapon_glock':
				case 'weapon_hkp2000':
				case 'weapon_usp_silencer':
				case 'weapon_p250':
				case 'weapon_deagle':
				case 'weapon_elite':
				case 'weapon_fiveseven':
				case 'weapon_revolver':
				case 'weapon_cz75a':
					wp['wp_s'] = weapon[1]
					break;
				case 'weapon_c4':
					wp['wp_c4'] = weapon[1];
					has_wpc4 = true
					break;
				case 'weapon_molotov':
				case 'weapon_incgrenade':
					wp['wp_ml'] = weapon[1];
					break;
				case 'weapon_flashbang':
					if (has_fl === true) {
						wp['wp_fl2'] = weapon[1]
						console.log(has_fl)
					} else {
						has_fl = true
						wp['wp_fl'] = weapon[1];
					}
					break;
				case 'weapon_smokegrenade':
					wp['wp_sm'] = weapon[1];
					break;
				case 'weapon_hegrenade':
					wp['wp_he'] = weapon[1];
					break;
				case 'weapon_decoy':
					wp['wp_dc'] = weapon[1];
					break;
				default:
					wp['wp_m'] = weapon[1]
					break;
			}
		}

	})
	if (window.wpc4['user'] == data.userid && has_wpc4 === false) {
		window.wpc4['user'] = -1
	} else if (has_wpc4 === true) {
		window.wpc4['user'] = data.userid
		window.wpc4['x'] = window.arr_plrs[data.userid].pos_x
		window.wpc4['y'] = window.arr_plrs[data.userid].pos_y
	}
	// console.log(window.wpc4['user'])
	return (
		// console.log(wp['wp_fl']),
		<div className='innn-b-weapons'>
			<RenderWeaponsBox box={'wp_c4'} weapon={wp.wp_c4} active_weapon={active_weapon} />
			<RenderWeaponsBox box={'wp_sm'} weapon={wp.wp_sm} active_weapon={active_weapon} />
			<RenderWeaponsBox box={'wp_fl'} weapon={wp.wp_fl} active_weapon={active_weapon} />
			<RenderWeaponsBox box={'wp_fl2'} weapon={wp.wp_fl2} active_weapon={active_weapon} />
			<RenderWeaponsBox box={'wp_ml'} weapon={wp.wp_ml} active_weapon={active_weapon} />

			<RenderWeaponsBox box={'wp_k'} weapon={wp.wp_k} active_weapon={active_weapon} />
			<RenderWeaponsBox box={'wp_t'} weapon={wp.wp_t} active_weapon={active_weapon} />
			<RenderWeaponsBox box={'wp_s'} weapon={wp.wp_s} active_weapon={active_weapon} />
			<RenderWeaponsBox box={'wp_m'} weapon={wp.wp_m} active_weapon={active_weapon} />
		</div>
	)
}
export default RenderWeapons