import React from 'react';
import SVG from 'react-inlinesvg';

const FA = ({ name, style }) => {
	{
		return (
			<>
				<SVG className={`fa-class fa-${style} fa-${name}`} src={`./img/font-awesome-pro/${style}/${name}.svg`} />
			</>
		)
	}
}
export default FA