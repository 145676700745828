import {cfg}                          	from './../../../../cfg/cfg_wsi.js';
import {cfg_maps}                          	from './../../../../cfg/cfg_maps.js';

export function radar_x_coord(ingame_x){
	return Math.round( (ingame_x - cfg_maps[window.curr_map]['pos_x']) / cfg_maps[window.curr_map]['scale']*window.radar_scale * (cfg.width/1024))+window.radar_trans_zx;
}
export function radar_y_coord(ingame_y){
	return - Math.round( (ingame_y - cfg_maps[window.curr_map]['pos_y']) / cfg_maps[window.curr_map]['scale']*window.radar_scale * (cfg.width/1024)-window.radar_trans_zy);
}
export function ingame_x_coord(radar_x){
	return  Math.round(radar_x - cfg_maps[window.curr_map]['pos_x']) / cfg_maps[window.curr_map]['scale'] * (cfg.width/1024)
}
export function ingame_y_coord(radar_y){
	return Math.round( (-radar_y * cfg_maps[window.curr_map]['scale']) + (cfg_maps[window.curr_map]['pos_y']) );
}