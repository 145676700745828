import React, { useState } from 'react';
// const teamMap = ['t', 'ct']
import EventBus from './../Events/EventBus.js';
import FA from './../../../../font-awesome-pro';

const PlayersTitle = (data) => {
	// console.log(data.isShort)
	// let isShort
	// (data?.isShort===undefined || data?.isShort===false)?isShort=false:isShort=true
	// console.log(data?.['isShort'])
	return (
		<div className={`toggle_short_info in-a-kdh side-${data.side} ${(data.isShort === true) && "short"}`} onClick={() => EventBus.emit('e_SetShort')}>
			<div className='fake_box'></div>
			<div className='innn-a-name'>
				<div className='name-plrs'>Ник</div>
				<img title='' src='' name='' className='img-svg wp-c4'></img>
			</div>
			<div className={`innn-a-kd kd-side-${data.side}`}>
				<i className='kd-v ping' title='Пинг'><FA name="signal-bars" style="duotone" /></i>
				<i className='kd-v mvps' title='Самый ценный игрок раунда'><FA name="star" style="duotone" /></i>
				<i className='kd-v deaths' title='Смертей'>D</i>
				<i className='kd-v assists' title='Помощь убийства'>A</i>
				<i className='kd-v kills' title='Убийств'>K</i>
				<i className='kd-v score' title='Счет'>S</i>
			</div>
			<div className='innn-a-kd2 hiddenx'>
				<i className='kd-v' title='Кол-во попаданий в голову'></i>
				<i className='kd-v' title='Процент попадайний в голову'></i>
				<i className='kd-v' title='Соотношение (Убийств+Помощи)/Смертей'></i>
				<i className='kd-v' title='Соотношение убийств/смертей'></i>
			</div>
		</div>
	)
}
export default PlayersTitle