import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { useSelector } from 'react-redux';
import PlayersSort from './../structure/MainPage/MainBox/Players/PlayersSort.js';
import ConnectIo from './../socket/ConnectIo.js';
import { radar_x_coord, radar_y_coord } from './../structure/MainPage/MainBox/Canvas/CoordConvert.js';
// import {render_map}                     from './structure/MainPage/MainBox/Canvas/RenderMap.js';
import { storeServersList } from './../store/StoreServersList.js';
import { storePlayersList } from './../store/StorePlayersList.js';
import { storeServer } from './../store/StoreServer.js';
import EventBus from './../structure/MainPage/MainBox/Events/EventBus.js';

function c(c) { console.log(c) }

export class ServersSteam {
	StartSteam() {
		window.socket.on('cs2_rooms', (data) => {
			let payload = JSON.parse(data);
			storeServersList.dispatch({ type: 'UPDATE_DATA', payload });
		})
		let fps = 0

		let time_s = Math.round(new Date().getTime() / 1000)
		window.socket.on('message', (message) => {
			let time = new Date().getTime()
			// c('message')
			let UsersTeam = new Array()
			UsersTeam['1'] = []
			UsersTeam['2'] = []
			UsersTeam['3'] = []
			let json_data = JSON.parse(message)
			let payload
			if (localStorage['sid'] != json_data['serverid']) return
			if (json_data['tact'] != 13) {
				// window.arr_plrs={}
				// return
			}
			if (json_data['server'] != undefined) {
				// if(window.curr_map !=json_data['server']['map'] && json_data['server']['map']!=undefined)
				// render_map(json_data['server']['map'])

			}
			if (json_data['server']) {
				if (json_data['server']['server_full']) {
					payload = json_data['server']
					storeServer.dispatch({ type: 'UPDATE_DATA', payload });
				}
			}
			let new_plr = false
			window.json_data = json_data
			json_data['players'].map(player => {
				let userid = player['userid']
				if (userid == undefined) return
				if (window.arr_plrs[userid] == undefined) {
					window.arr_plrs[userid] = new Array()
					window.arr_plrs[userid]['top'] = 1
					window.arr_plrs[userid]['has_weapon_c4'] = false
					new_plr = true
					window.arr_hurt[userid] = new Array()
					window.arr_blind[userid] = new Array()
					window.arr_fire[userid] = new Array()
					console.log('Добавлен игрок')
				}
				if (player['team'] != undefined) {
					let top = window.arr_plrs[userid]['top']
					if (player['health'] < window.arr_plrs[userid]['health'] && player['alive']===true) {//кастомное событие урона игроку
						(json_data['events'] == undefined) && (json_data['events'] = [])
						json_data['events'][json_data['events'].length] =
						{
							"serverid": json_data['serverid'],
							"event_name": "k_EventPlayerDamage",
							"userid": userid,
						}
					}
					window.arr_plrs[userid] = player
					window.arr_plrs[userid]['top'] = top
					window.arr_plrs[userid]['update'] = time
				} else {
					window.arr_plrs[userid]['position'] = player['position']
					window.arr_plrs[userid]['rotation'] = player['rotation']
				}
				if (window.arr_plrs[userid]['position']['x'] != undefined) {
					window.arr_plrs[userid]['pos_x'] = radar_x_coord(window.arr_plrs[userid]['position']['x'])
					// console.log( radar_x_coord(window.arr_plrs[userid]['position']['x']))
					window.arr_plrs[userid]['pos_y'] = radar_y_coord(window.arr_plrs[userid]['position']['y'])
				}

				if (UsersTeam[window.arr_plrs[userid]['team']] == undefined) UsersTeam[window.arr_plrs[userid]['team']] = new Array()
				UsersTeam[window.arr_plrs[userid]['team']].push(window.arr_plrs[userid])
			})
			
			payload = UsersTeam
			storePlayersList.dispatch({ type: 'UPDATE_DATA', payload });
			
			PlayersSort(UsersTeam)

			/*Счетчик данных в виде фпс */
			window.fps_t++
			let msg_time = Math.round(new Date().getTime() / 1000)
			if (time_s < msg_time) {
				// fpsMetter.render(window.fps);
				window.fps = window.fps_t
				window.fps_t = 0
				time_s = msg_time
			}
			/**/

		})
	}
}
