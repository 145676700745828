export default function PlayersSort (UsersTeam) {// return 
    if(UsersTeam==undefined || UsersTeam==null) return false
    // console.log(UsersTeam)
	for (let step = 2; step < 4; step++) {//перебор команд
        if(UsersTeam[step]==undefined){return;}
        UsersTeam[step].sort(function (a, b) {//базовая сортировка массива
            if (a.kills < b.kills) {
                return 1;
            }
            if (a.kills > b.kills) {
                return -1;
            }
            return 0;
        })
        let top=1
        UsersTeam[step].forEach(player => {//изменения top по итогам сортировки массива
            // console.log(player)
            top++
            window.arr_plrs[player['userid']]['top']=top-2
        });
    }

    // return new_UsersTeam;
}