export const cfg = {
	'bomb_time_detonate'		: 40000,				//время до взрыва бомбы в мс
	'team_2_color' 				: 'yellow',				//цвет для игроков за T
	'team_2_color_name' 		: 'yellow',				//цвет ников для игроков за T на карте 
	'team_3_color' 				: 'blue',				//цвет для игроков за CT
	'team_3_color_name' 		: '#1E90FF',			//цвет ников для игроков за CT на карте 
	
	'name_background_color'		: '#00000078',			//цвет фоана ников для всех игроков на карте 

	'time_alive_msg' 			: 6000,					//время жизни уведомления о убийстве в мс
	'plrs_width' 				: 5,					//размер игроков на карте
	'plrs_line_width' 			: 10, 					//ширина линии игрока
	'width' 					: 826,					//размер карты в px
	
	
	'alive_name_color'	 		: "white",				//не используется
	'alive_name_font'	 		: "11px sans-serif",	//лучше не менять
	'alive_name_height' 		: 30,					//лучше не менять
	'alive_name_top' 			: 20,					//лучше не менять

	'death_name_color'	 		: "#aaaaaa",
	'death_name_font'	 		: "9px sans-serif",		//лучше не менять
	'death_name_height'	 		: 25,					//лучше не менять
	'death_name_top'	 		: 15,					//лучше не менять
	
	
	'color_flash'				: '#ffffff5c',			//цвет взырва flash
	'color_he'					: '#ff0000',			//цвет взырва he
	
	//при изменении время в тикрейтах не забывайте что кол-во тиков мониторинга не стабильно и находится в пределах +-30 кадров/сек
	'kill_trajectory_length'	: 6,					//время в tick отображения траектория убийства
	'kill_trajectory_color'		: '#ff0000',			//цвет траектории убийства
	
	'fire_trajectory_length'	: 12,					//время в tick отображения траектория выстрела
	'fire_trajectory_color'		: '#A9A9A9',			//цвет выстрелов

	'bg_win_team_1'				: '#585858',			//
	'bg_g_win_team_1'			: '#58585899',			//

	'bg_win_team_2'				: '#a67447',			//
	'bg_g_win_team_2'			: '#a6744799',			//

	'bg_win_team_3'				: '#000073',			//
	'bg_g_win_team_3'			: '#0000b499',			//
	
}
// window.arr_wins = {'UNDEFINED',	'CT_WIN_ELIMINATION', 'CT_WIN_RESCUE', 'CT_WIN_DEFUSE', 'CT_WIN_TIME', 'T_WIN_ELIMINATION', 'T_WIN_BOMB', 'T_WIN_TIME'}

window.arr_servers_sort = [
	'912111173227015', 
	// '912111173227016', 
	// '912111173227017', 
	// '912111173227018', 
	// '912111173227019', 
	'912111173227020'
];
window.arr_sfui = {
	"#SFUI_Notice_Round_Draw":{
		"title"		: "Ничья"
	},
	"#SFUI_Notice_Target_Bombed":{
		"title"		: "Цель Взорвана"
	},
	"#SFUI_Notice_Target_Saved":{
		"title"		: "Цель Спасена"
	},
	"#SFUI_Notice_Target_Saved_DeFused":{
		"title"		: "Бомба обезврежена."
	},
	"#SFUI_Notice_Target_Exploded":{
		"title"		: "Цель взорвана"
	},
	"#SFUI_Notice_CTs_Win":{
		"title"		: "Counter-Terrorists победили"
	},
	"#SFUI_Notice_Terrorists_Win":{
		"title"		: "Terrorists победили"
	},
	"#SFUI_Notice_Bomb_Defused":{
		"title"		: "Бомба обезврежена"
	},
	"#SFUI_Notice_Bomb_Exploded":{
		"title"		: "Бомба взорвана"
	},
	"#SFUI_Notice_Hostages_Rescued":{
		"title"		: "Заложники спасены"
	},
	"#SFUI_Notice_Game_Commencing":{
		"title"		: "Игра начинается"
	},
}
window.arr_wins_desc = {
	'0' : {
		'name' 		: 'UNDEFINED',
		'desc' 		: 'неизвестно',
		'color' 	: 'black',
	},
	'1' : {//цель взорвана
		'name' 		: 'CT_WIN_ELIMINATION',
		'desc_head' : 'Counter-Terrorists победили',
		'desc' 		: 'CT уничтожили всех противников',
		'team' 		: '2',
		'color' 	: '#1a1b34',
	},
	'2' : {//
		'name' 		: 'CT_WIN_RESCUE',
		'desc_head' : 'Counter-Terrorists победили',
		'desc' 		: 'CT спасли заложника',
		'team'		: '2',
		'color'		: '#1a1b34',
	},
	'3' : {
		'name' 		: 'CT_WIN_DEFUSE',
		'desc_head' : 'Counter-Terrorists победили',
		'desc' 		: 'CT побеждают раздефузив бомбу',
		'team' 		: '2',
		'color' 	: '#1a1b34',
	},
	'4' : {
		'name' 		: 'CT_WIN_TIME',
		'desc_head' : 'Counter-Terrorists победили',
		'desc' 		: 'CT победили по истечению времени',
		'team' 		: '2',
		'color' 	: '#1a1b34',
	},
	'5' : {
		'name' 		: 'T_WIN_ELIMINATION',
		'desc_head' : 'Terrorists победили',
		'desc' 		: 'T уничтожили всех противников',
		'team' 		: '3',
		'color' 	: '#4c3231',
	},
	'6' : {
		'name' 		: 'T_WIN_BOMB',
		'desc_head' : 'Terrorists победили',
		'desc' 		: 'T победили взорвав бомбу',
		'team' 		: '3',
		'color' 	: '#4c3231',
	},
	'7' : {
		'name' 		: 'T_WIN_TIME',
		'desc_head' : 'Terrorists победили',
		'desc' 		: 'T победили  по истечению времени',
		'team' 		: '3',
		'color' 	: '#4c3231',
	},
}