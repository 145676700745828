export class ConnectIo
{
    Connect(sid){
        if(localStorage['sid']!=undefined)window.socket.emit('leave', localStorage['sid'])
        localStorage.setItem("sid", sid);
        localStorage.setItem("sid_time", new Date().getTime() / 1000);
        console.log(sid)
        window.socket.emit('join', sid)
        window.arr_plrs={}
    }
}
export default ConnectIo