import { radar_x_coord, radar_y_coord } from './CoordConvert.js';
import { cfg } from './../../../../cfg/cfg_wsi.js';
import EventBus from './../Events/EventBus.js';

let arr_delete_hurt_weapon = new Array();
arr_delete_hurt_weapon = ['he', 'hegrenade', 'flash', 'molotov', 'smoke']


function c(c) { console.log(c) }

export function EventsIngame(arr) {
	if (arr == undefined) { return }
	// if (arr['events'].length == 0) { return }
	// console.log(window.arr_fe.length)
	if(window.arr_fe.length>0){
		arr = arr.concat(window.arr_fe)
		window.arr_fe=[]
		// console.log(arr)
	}
	let time_ms = new Date().getTime()
	arr.forEach(function (item, i, arr_in) {
		if (item == null) return
		switch (item['event_name']) {
			case 'EventPlayerChat':
				// c(item)
				// $('.chat_server_body').append(`
				// 	<div class='msg_box' data-userid='`+item['userid']+`' data-steamid='`+item['steamid']+`' data-time='`+item['event_time']+`'>
				// 		<div class='msg_name' style='color:`+cfg['team_'+item['team']+'_color_name']+`'>`+item['name']+`: </div>
				// 		<div class='msg_text'>[ВСЕМ] `+item['text']+`</div>
				// 	</div>
				// `)
				EventBus.emit('EventPlayerChat', { item });
				break
			case 'k_EventPlayerDamage':
				// console.log('EventPlayerDamage')
				EventBus.emit('EventPlayerDamage', { item });
				break;
			case 'EventPlayerDeath':
				EventBus.emit('EventPlayerDeath', { item });
				break;
			case 'EventRoundStart':
				EventBus.emit('EventRoundStart', { item });
				// if(window.interval_bomb!=undefined){clearInterval(interval_bomb)}
				// time_line_update=true
				// $('.time_line').removeClass('bomb')
				// sort_players()
				// $('.plrs-in-innm-c').html('')//очистка round kills
				// setTimeout(function(){
				// 	short_visible = 1
				// 	toggle_visible_plrs()
				// },'3000' );

				// json_data['players'].forEach(function(item, i, arr) {
				// 	arr_grenade[item['userid']] = []
				// 	arr_blind[item['userid']] = 0
				// })
				break;
			case 'EventRoundEnd':
				EventBus.emit('EventRoundEnd', { item });
				// c('EventRoundEnd')
				// short_visible = 0
				// toggle_visible_plrs()
				// $('.display_info>h').html( window.arr_sfui[item['message']]['title'] )
				// // $('.display_info>i').html( 'текст')
				// $('.display_info').css({'--color_w_bg':window.cfg['bg_win_team_'+item['winner']]})
				// $('.display_info').css({'--color_w_bg2':window.cfg['bg_g_win_team_'+item['winner']]})
				// $('.display_info_parent').addClass('active')
				// setTimeout(function(){
				// 	$('.display_info_parent').removeClass('active')
				// 	$('.display_info').css({'--color_w_bg':'transparent'})
				// 	$('.display_info').css({'--color_w_bg2':'transparent'})
				// },'5000' );
				break;
			case 'EventBombBeep':
				EventBus.emit('EventBombBeep', { item });
				// // c(item)
				// $('.time_line').addClass('beep')
				// setTimeout(() => {
				// 	$('.time_line').removeClass('beep')
				// }, 150);
				break;
			// case 'EventBombBeep':
			// 	c(item)
			// break;
			// case 'EventCsWinPanelRound':
			// 	c('EventCsWinPanelRound')
			// 	c(item)
			// 	short_visible = 0
			// 	toggle_visible_plrs()
			// 	// c(window.arr_wins_desc)
			// 	// c(arr_wins_desc)
			// 	$('.display_info>h').html( window.arr_wins_desc[arr['round_wins'][Object.keys(arr['round_wins']).length - 1]]['desc_head'] )
			// 	$('.display_info>i').html( window.arr_wins_desc[arr['round_wins'][Object.keys(arr['round_wins']).length - 1]]['desc'] )
			// 	$('.display_info').css({'opacity':'1'})
			// 	$('.display_info').css({'background':window.arr_wins_desc[arr['round_wins'][Object.keys(arr['round_wins']).length - 1]]['color']})
			// 	setTimeout(function(){
			// 		$('.display_info').css({'opacity':'0'})
			// 	},'5000' );


			// break;
			case 'player_hurt':
				EventBus.emit('player_hurt', { item });
				// console.log('player_hurt'+item['userid'])
				// var hp_box_bottom = $('.plrs_inform[data-id="'+item['userid']+'"]').find('.hp_box_bottom')
				// hp_box_bottom.css({'background':'red'})
				// setTimeout(function(){
				// 	hp_box_bottom.css({'background':''})
				// },'2500' );


				//добавляет в массив `arr_hurt` запись попадания пули. отрисовка происходит в функции рендере игроков (render_players())
				/*в item массиве содержится:
					armor: 0
					attacker: 3399
					dmg_armor: 0
					dmg_health: 113
					game_time: 2763.53125
					health: 0
					hitgroup: 2
					name: "player_hurt"
					userid: 3453
					weapon: "awp"
				*/
				//позиции игроков можно брать из массива 'arr_pos'. он обновляется с каждым кадром в render_players()
				// c(arr_pos.length)
				break;
			case 'weapon_fire':

				break;
			case "hegrenade_detonate":

				break;
			case 'flashbang_detonate':

				break;
			case "smokegrenade_detonate":

				break;
			case 'sfuievent':

				break
			case 'grenade_bounce':

				break;
			case 'EventBulletImpact':
				EventBus.emit('EventBulletImpact', { item });
				let userid = item['userid']
				if (!window.arr_plrs[userid]) return
				if (window.arr_plrs[userid]['pos_x'] == '0' || window.arr_plrs[userid]['pos_x'] == undefined) {
					c('arr pos is null')
					return false;
				}
				let x_userid = window.arr_plrs[userid]['pos_x']
				let y_userid = window.arr_plrs[userid]['pos_y']

				let x_imact = radar_x_coord(item['pos_imact']['x'])
				let y_imact = radar_y_coord(item['pos_imact']['y'])
				if (window.arr_fire?.userid == undefined) window.arr_fire[userid] = new Array()
				window.arr_fire[userid] = {						//запись в массив данных для последующией отрисовки в render_players()
					'tick': cfg.fire_trajectory_length,
					'pos_userid': {
						'x': x_userid,
						'y': y_userid,
					},
					'pos_imact': {	//!координаты приходят в  x y z, нужно обработать
						'x': x_imact,
						'y': y_imact,
					}
				}
				// console.log(window.arr_fire[userid])
				break;
			// case "smokegrenade_expired":
			// case "tagrenade_detonate":
			// case "decoy_started":
			// case "decoy_firing":
			// case "decoy_detonate":
			// case "molotov_detonate":
			// case "inferno_startburn":
			// case "inferno_expire":
			// case "inferno_extinguish":

			case "EventPlayerBlind":
				EventBus.emit('EventPlayerBlind', { item });
				// c('EventPlayerBlind')
				//добавляет в массив `arr_blind` запись что игрок ослеплен (blind_duration). отрисовка происходит в функции рендере игроков (render_players)
				/*в item массиве содержится:
				attacker: 2189
				blind_duration: 3.0047004222869873
				game_time: 3046
				name: "player_blind"
				userid: 2186*/
				// let userid = item['userid']
				window.arr_blind[item['userid']] = (Number(item['blind_duration'].replace(',', '.')) * 1000) + time_ms
				// c('ex'+(Number(item['blind_duration'].replace(',', '.'))*1000)+Number(time_ms))
				// c(arr_blind[userid])
				break;

			// case "round_end":
			// case "round_announce_match_point": 
			// case "round_announce_final": 
			// case "round_announce_last_round_half": 
			// case "round_announce_match_start": 
			// case "round_announce_warmup": 
			// case "round_time_warning": 
			// case "round_freeze_end": 
			// case "cs_win_panel_match": 
			// display_info

			// case 'bomb_beginplant':
			// case 'bomb_abortplant':
			case 'bomb_planted':
				// window.bomb_starttime = Math.floor(new Date().getTime())
				// // bomb_timeline()
				break;
			case 'bomb_defused':
				c(item)
				break;
			case 'bomb_exploded':
				c(item)
				break;
			// case 'bomb_pickup':
			// 	var x = radar_x_coord(x)
			// 	var y = radar_y_coord(y)
			// 	bomb_pos={
			// 		x: radar_x_coord(item['origin']['x']),
			// 		y: radar_y_coord(item['origin']['y']),
			// 		user:item['userid'],
			// 		status:'pickup'
			// 	}
			// break;
			case 'bomb_dropped':
				// bomb_pos={
				// 	x: radar_x_coord(item['origin']['x']),
				// 	y: radar_y_coord(item['origin']['y']),
				// 	user:item['userid'],
				// 	status:'dropped'
				// }
				break;
			case 'bomb_begindefuse':
				c(item)
				break;
			case 'bomb_abortdefuse':
				c(item)
				break;

		}
		// console.log(item)
	})
}