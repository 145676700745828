import React from 'react';
import { useSelector } from 'react-redux';

const ServerList = () => {// return 
    const data = useSelector(state  => state.data);
    if(data==undefined)return false;
    return (
      <div className='servers-lblk-list'>  
        {data.map((server) => (
            <div key={server[0]} className={`servers-lblk-serv ${server[0]==localStorage['sid']&& ' active_server_room'} ${server[1].status}`}  data-map={server[1].map} data-num='' data-sid={server[0]} data-id='' onClick={() => window.connector.Connect(server[0])}>
                <div className={`status status_${server[1].status}`}></div>
                <img className='back_map' src={`//glite.com.ua/include/resource/maps/screenshots/360p/${server[1].map}.webp`}></img>
                <div className='l_inf_serv'>
                    <div className='more_inf_serv'><i>...</i></div>
                </div>
                <div className='r_inf_serv'>
                    <div className='serv-name'>{server[1].hostname}</div>
                        <div className='serv-map'>{server[1].map}</div>
                        <div className='count_plrs'>
                            {(server[1].status=='online')?
                                <>
                                    <i className='players'>{server[1].Players}</i>
                                    <i>/</i>
                                    <i className='max_players'>{server[1].MaxPlayers}</i>
                                </>
                            :server[1].status}
                        </div>
                    <div className='serv_uptime'>0ч. 0мин. 0сек.</div>
                </div>				
                <div className='progress'>
                    <div className='prgrs_val' style={{width:100/server[1].MaxPlayers*server[1].Players}}></div>
                </div>
            </div>
        ))}
  
      </div>
    );
};
export default ServerList