// import PlayersSort                      from './../structure/MainPage/MainBox/Players/PlayersSort.js';
import { cfg } from './../../../../cfg/cfg_wsi.js';
import React, { Component, useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
// import Konva from 'konva';
import { Stage, Layer, Shape, Circle, Image } from 'react-konva';
import { storePlayersList } from './../../../../store/StorePlayersList.js';
import UserPoint from './PartsRenderCanvasP/UserPoint.js';
import UserDamage from './PartsRenderCanvasP/UserDamage.js';

import EventBus from './../Events/EventBus.js';
import { EventsIngame } from "./EventsIngame.js";


const IconImage = ({ icon, x, y, h = 15, fill = 'white' }) => {
	const w = 15
	const ratio = (window.ui_icons[icon]?.width) ? window.ui_icons[icon]?.height / window.ui_icons[icon]?.width : '1';
	return <Image
		x={x - (w / 2)}
		y={y - (w / 2)}

		width={h / ratio}
		height={h}
		color={fill}
		image={window.ui_icons[icon]}
	/>;
};

const handleCircleClick = (e) => {
	EventBus.emit('ClickCircle', { e });
};

let selectUser;
let selectUserCircle = 0;

// var arr_users_damage = []
const handleUserClick = ({ userid }) => {
	(selectUser === userid) ? selectUser = undefined : selectUser = userid
	selectUserCircle = 1
};


var arr_fire_xy = []
const PlayersLayeShaper = (server) => {
	useEffect(() => {
		EventBus.on('handleUserClick', handleUserClick);
		return () => {
			EventBus.off('handleUserClick', handleUserClick);
		};
	}, []);
	// console.log(arr_fire_xy)
	const players = useSelector(store => store.data);
	const time = new Date().getTime()
	server = server['server']
	// console.log(players)
	const w = 8
	let x = 0;
	let y = 0;
	let angle = 0;
	let userid = 0;
	let fRGSR = 0;
	let color = 'white';
	let arr_fire = [];
	let bombx = window.wpc4['x'];
	// console.log(bombx)
	let bomby = window.wpc4['y'];

	window.json_data['events'] = window.json_data['events'] || [];
	window.json_data['fake_events'] = window.json_data['fake_events'] || [];
	window.json_data['fake_events'] = undefined

	EventsIngame(window.json_data['events'])
	// console.log(window.json_data['events'])

	return (
		<>
			{Object.entries(window.arr_plrs).map((item) => (
				// fRGSR=undefined,
				(item && item[1] && item[1]['pos_x'] != undefined) && (
					item = item[1],
					userid = item['userid'],
					x = item['pos_x'],
					y = item['pos_y'],
					angle = item['rotation']?.y + 90,
					arr_fire = window.arr_fire[item['userid']],
					color = (selectUser === item['userid'] ? 'white' : cfg['team_' + item['team'] + '_color']),
					// fRGSR=9 ,


					(window.arr_fire[userid] !== undefined && window.arr_fire[userid]?.tick !== undefined) && (
						// console.log(userid),
						userid = item['userid'],
						(arr_fire_xy?.[userid] === undefined) && (
							arr_fire_xy[userid] = new Array(),
							arr_fire_xy[userid]['tact'] = 0,
							arr_fire_xy[userid]["f_x"] = x,
							arr_fire_xy[userid]["f_y"] = y,
							arr_fire_xy[userid]["t_x"] = arr_fire['pos_imact']['x'],
							arr_fire_xy[userid]["t_y"] = arr_fire['pos_imact']['y']
						),


						//расчет траэктории полета пули
						arr_fire_xy[userid]['tact'] = arr_fire_xy[userid]['tact'] + 0.1,
						arr_fire_xy[userid]["f_x"] = Math.round(arr_fire_xy[userid]["f_x"] + (arr_fire_xy[userid]["t_x"] - arr_fire_xy[userid]["f_x"]) * (arr_fire_xy[userid]['tact'] - .1)),
						arr_fire_xy[userid]["f_y"] = Math.round(arr_fire_xy[userid]["f_y"] + (arr_fire_xy[userid]["t_y"] - arr_fire_xy[userid]["f_y"]) * (arr_fire_xy[userid]['tact'] - .1)),

						arr_fire_xy[userid]["ct_x"] = Math.round(arr_fire_xy[userid]["f_x"] + (arr_fire_xy[userid]["t_x"] - arr_fire_xy[userid]["f_x"]) * arr_fire_xy[userid]['tact']),
						arr_fire_xy[userid]["ct_y"] = Math.round(arr_fire_xy[userid]["f_y"] + (arr_fire_xy[userid]["t_y"] - arr_fire_xy[userid]["f_y"]) * arr_fire_xy[userid]['tact']),
						(arr_fire_xy[item['userid']]['tact'] >= 0.98) && (
							window.arr_fire[item['userid']] = undefined,
							arr_fire_xy[item['userid']] = undefined
						)
					)
				),
				// console.log(selectUser),
				// (arr_users_damage[userid] != undefined)
				// ? fRGSR = true
				// : fRGSR = false,
				<React.Fragment key={userid}>
					{/*Моделль игрока */}

					{(item.alive) ?
						(window.wpc4['user'] !== item['userid']) ?
							<>
								<Circle
									key={`Circle` + item['userid']}
									name={`Circle` + item['userid']}
									userid={item['userid']}
									zIndex={(selectUser === item['userid'] ? '2' : '1')}
									x={x}
									y={y}
									shadowColor={'black'}
									shadowBlur={3}
									// shadowOffset= {{ x: 10, y: 10 }}
									shadowOpacity={1}
									fill={color}
									// fillRadialGradientStartRadius={fRGSR}
									// fillRadialGradientEndRadius={10}
									// fillRadialGradientColorStops={[0, color, 1, 'red']}
									radius={9}
									onclick={handleCircleClick}
								/>
								< UserDamage userid={item['userid']} x={x} y={y} />
							</>

							// selectUser
							: <>
								<Circle
									key={`Circle` + item['userid']}
									name={`Circle` + item['userid']}
									userid={item['userid']}
									x={x}
									y={y}
									stroke={cfg['team_' + item['team'] + '_color']}
									strokeWidth={2}
									radius={8}
									onclick={handleCircleClick}
								/>
								<IconImage x={x + 3} y={y} h={13} icon='weapon_c4' />
							</>
						: (<IconImage x={x} y={y} icon='elimination' />)

					}

					{(selectUser === item['userid'] && selectUserCircle > 0 && selectUserCircle < 175) && (
						selectUserCircle = selectUserCircle + 4,
						(selectUserCircle >= 175) && (selectUserCircle = 0),
						<UserPoint stage={selectUserCircle} x={x} y={y} />
					)}

					{/* {console.log(arr_fire_xy[userid]["f_x"])} */}

					{(arr_fire_xy[item['userid']]?.['f_x'] != undefined) &&
						<>
							<Shape
								key={`bullet_traser` + item['userid']}
								sceneFunc={function (context, shape, _fire_xy = arr_fire_xy[item['userid']]) {
									// console.log(_fire_xy)

									context.beginPath();
									context.moveTo(_fire_xy?.["f_x"], _fire_xy?.["f_y"]);
									context.lineTo(_fire_xy?.["ct_x"], _fire_xy?.["ct_y"]);
									context.closePath();
									// (!) Konva specific method, it is very important
									context.fillStrokeShape(shape);
								}}
								stroke="white"
								strokeWidth={0.3}
							/>
						</>
					}

					{/**/}
					{/* <CanvasUserName x={x} y={y} name={item.name}/>                        */}
					<Shape
						key={`Shapex` + item['userid']}
						name={`Shapex` + item['userid']}
						sceneFunc={function (context, shape) {
							const x = item['pos_x']
							const y = item['pos_y']
							// console.log(window.arr_plrs.userid)
							const status_plr = (item.alive) ? 'alive' : 'death'
							const width = Math.round(context.measureText(item.name).width)
							context.beginPath();
							context.font = cfg[status_plr + '_name_font'];
							context.fillStyle = `#00000078`;
							context.rect(x - (width / 2) - 5, y - (cfg[status_plr + '_name_height']), width + 10, 14);//черный фон ника
							context.fill();
							context.textAlign = 'center';
							context.fillStyle = cfg["team_" + item.team + "_color_name"];
							context.fillText(item.name, x, y - cfg[status_plr + '_name_top']);//вывод ника над игроком
							// context.fillText(arr_users_damage?.[item.userid]?.['fRGSR'], x, y - cfg[status_plr + '_name_top']);//вывод ника над игроком

							if (time - item.update > 1000) {
								context.beginPath();
								context.fillStyle = `#00000078`;
								context.rect(x - 14.5 - 5, y - (cfg[status_plr + '_name_height']) - 14, 29 + 10, 14);
								context.fill();
								context.fillStyle = 'red'
								context.fillText("offline", x, y - cfg[status_plr + '_name_top'] - 14)//offline
								if (time - item.update > 10000) {
									delete window.arr_plrs[item.userid]
								}
							}

						}}
						fill="white"
					/>

					{/*Курсор поворота*/}
					{(item.alive) &&
						// console.log(item.alive)
						<>
							<Shape
								key={`Shape` + item['userid']}
								name={`Shape` + item['userid']}
								sceneFunc={function (context, shape) {
									const x = item['pos_x']
									const y = item['pos_y']
									const angle = item['rotation']['y'] + 90
									context.beginPath();
									context.translate(x, y);
									context.rotate((angle - (angle * 2)) * Math.PI / 180);
									context.translate(-x, -y);
									context.moveTo(x, y + (w * 1.4));
									context.lineTo(x - (w * 0.9), y + (w * 1));
									context.lineTo(x, y + (w * 2));
									context.lineTo(x + (w * 0.9), y + (w * 1));
									context.closePath();
									// (!) Konva specific method, it is very important
									context.fillStrokeShape(shape);
								}}
								fill="white"
							/>
							<IconImage x={x + 20} y={y - 10} icon={item.active_weapon} />
						</>
					}
					{/**/}
					{(bombx !== undefined && server.bomb_planted === true) && <IconImage x={bombx} y={bomby} h={24} fill='red' icon='bomb_c4_red' />}
					{(bombx !== undefined && window.wpc4['user'] === -1 && server.bomb_planted === false) &&
						<>
							<IconImage x={bombx} y={bomby} h={20} icon='bomb_c4_red' />
							<Shape
								key={`pulse_c4` + item['userid']}
								name={`pulse_c4` + item['userid']}
								sceneFunc={function (context, shape) {
									const x = item['pos_x']
									const y = item['pos_y']
									window.wpc4['scale']++
									context.setTransform(window.wpc4['scale'], 0, 0, window.wpc4['scale'], 0, 0);
									context.arc(x, y, 30, 0, Math.PI * 2);
								}}
								fill="white"
							/>
						</>
					}
				</React.Fragment>
			))}
		</>
	)
	// }
	// requestAnimationFrame(render())
}

const RenderPlayersCanvas = ({ server }) => {
	if (server?.map == undefined) return ''
	return (
		<Provider store={storePlayersList}>
			<Stage width={cfg.width} height={cfg.width} className='CanvasPlayrs' id='radar_monitor_img_playrs'>
				<Layer>
					<PlayersLayeShaper store={storePlayersList} server={server} />
				</Layer>
			</Stage>
		</Provider>
	);
}
export default RenderPlayersCanvas 
